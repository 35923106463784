import React from 'react'

// components
import { Button } from '@mui/material'

export default function generate({
  t,
  classes,
  onModifyControlLocation,
  onDeleteControlLocation,
}) {
  return [
    {
      name: 'name',
      label: t('control-locations-list.control-location-table-header'),
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModifyControlLocation(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDeleteControlLocation(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
