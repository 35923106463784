import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// components
import Widget from '../../components/Widget'
import PageTitle from '../../components/PageTitle'
import { useDataApi } from '../../context/DataApiContext'
import Loading from '../../components/Loading'
import CreateVehicleTypeDialog from '../../components/VehicleType/components/CreateVehicleTypeDialog'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import CreateChassisDialog from '../../components/Chassis/components/CreateChassisDialog'
import CreateVehicleTypeBulkDialog from '../../components/VehicleType/components/CreateVehicleTypeBulkDialog'
import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'
import BFTableInfoCard from 'shared-components/table-info-card'
import generateColumns from './columns'
import { useQueryState } from '../../utils/query-state'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function Dashboard({ history }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { user } = useUserState()

  const query = useQuery()

  const [failedLoad, setFailedLoad] = useState(false)
  const [counts, setCounts] = useState(null)
  const [openCreateVehicleTypeDialog, setOpenCreateVehicleTypeDialog] =
    useState(false)
  const [openCreateChassisDialog, setOpenCreateChassisDialog] = useState(false)
  const [openCreateVehicleTypeBulkDialog, setOpenCreateVehicleTypeBulkDialog] =
    useState(false)

  const [addActionsAnchorEl, setAddActionsAnchorEl] = useState({
    anchorEl: null,
    items: [],
  })

  // 1st control Vehicle Type Table
  const [
    vehiclesWaitingFirstControlByVehicleTypeTablePage,
    setVehiclesWaitingFirstControlByVehicleTypeTablePage,
  ] = useQueryState({
    paramName: 'vehiclesWaitingFirstControlByVehicleTypePage',
    defaultValue: 0,
    history,
    query,
  })

  const [
    vehiclesWaitingFirstControlByVehicleTypeTableState,
    setVehiclesWaitingFirstControlByVehicleTypeTableState,
  ] = useState({
    page: vehiclesWaitingFirstControlByVehicleTypeTablePage,
    limit: 5,
    filters: {
      groupBy: 'vehicleType',
    },
  })
  const [
    vehiclesWaitingFirstControlByVehicleTypeTableData,
    setVehiclesWaitingFirstControlByVehicleTypeTableData,
  ] = useState(null)

  // 1st control Constructor Table
  const [
    vehiclesWaitingFirstControlByConstructorTablePage,
    setVehiclesWaitingFirstControlByConstructorTablePage,
  ] = useQueryState({
    paramName: 'vehiclesWaitingFirstControlByConstructorPage',
    defaultValue: 0,
    history,
    query,
  })
  const [
    vehiclesWaitingFirstControlByConstructorTableState,
    setVehiclesWaitingFirstControlByConstructorTableState,
  ] = useState({
    page: vehiclesWaitingFirstControlByConstructorTablePage,
    limit: 5,
    filters: {
      groupBy: 'constructor',
    },
  })
  const [
    vehiclesWaitingFirstControlByConstructorTableData,
    setVehiclesWaitingFirstControlByConstructorTableData,
  ] = useState(null)

  // Add state for selected view persistence
  const [
    vehiclesWaitingFirstControlSelectedView,
    setVehiclesWaitingFirstControlSelectedView,
  ] = useQueryState({
    paramName: 'vehiclesWaitingFirstControlSelectedView',
    defaultValue: 0,
    history,
    query,
  })

  // Recontrol Vehicle Type Table
  const [
    vehiclesWaitingRecontrolByVehicleTypeTablePage,
    setVehiclesWaitingRecontrolByVehicleTypeTablePage,
  ] = useQueryState({
    paramName: 'vehiclesWaitingRecontrolByVehicleTypePage',
    defaultValue: 0,
    history,
    query,
  })

  const [
    vehiclesWaitingRecontrolByVehicleTypeTableState,
    setVehiclesWaitingRecontrolByVehicleTypeTableState,
  ] = useState({
    page: vehiclesWaitingRecontrolByVehicleTypeTablePage,
    limit: 5,
    filters: {
      groupBy: 'vehicleType',
      controlNumber: 2,
    },
  })
  const [
    vehiclesWaitingRecontrolByVehicleTypeTableData,
    setVehiclesWaitingRecontrolByVehicleTypeTableData,
  ] = useState(null)

  // Recontrol Constructor Table
  const [
    vehiclesWaitingRecontrolByConstructorTablePage,
    setVehiclesWaitingRecontrolByConstructorTablePage,
  ] = useQueryState({
    paramName: 'vehiclesWaitingRecontrolByConstructorPage',
    defaultValue: 0,
    history,
    query,
  })
  const [
    vehiclesWaitingRecontrolByConstructorTableState,
    setVehiclesWaitingRecontrolByConstructorTableState,
  ] = useState({
    page: vehiclesWaitingRecontrolByConstructorTablePage,
    limit: 5,
    filters: {
      groupBy: 'constructor',
      controlNumber: 2,
    },
  })
  const [
    vehiclesWaitingRecontrolByConstructorTableData,
    setVehiclesWaitingRecontrolByConstructorTableData,
  ] = useState(null)

  // Add state for selected view persistence for recontrol
  const [
    vehiclesWaitingRecontrolSelectedView,
    setVehiclesWaitingRecontrolSelectedView,
  ] = useQueryState({
    paramName: 'vehiclesWaitingRecontrolSelectedView',
    defaultValue: 0,
    history,
    query,
  })

  // Add state for Vehicles OK or Reservations by Vehicle Type
  const [
    vehiclesOkOrReservationsByVehicleTypeTablePage,
    setVehiclesOkOrReservationsByVehicleTypeTablePage,
  ] = useQueryState({
    paramName: 'vehiclesOkOrReservationsByVehicleTypePage',
    defaultValue: 0,
    history,
    query,
  })

  const [
    vehiclesOkOrReservationsByVehicleTypeTableState,
    setVehiclesOkOrReservationsByVehicleTypeTableState,
  ] = useState({
    page: vehiclesOkOrReservationsByVehicleTypeTablePage,
    limit: 5,
    filters: {
      groupBy: 'vehicleType',
    },
  })

  const [
    vehiclesOkOrReservationsByVehicleTypeTableData,
    setVehiclesOkOrReservationsByVehicleTypeTableData,
  ] = useState(null)

  // Add state for Vehicles OK or Reservations by Constructor
  const [
    vehiclesOkOrReservationsByConstructorTablePage,
    setVehiclesOkOrReservationsByConstructorTablePage,
  ] = useQueryState({
    paramName: 'vehiclesOkOrReservationsByConstructorPage',
    defaultValue: 0,
    history,
    query,
  })

  const [
    vehiclesOkOrReservationsByConstructorTableState,
    setVehiclesOkOrReservationsByConstructorTableState,
  ] = useState({
    page: vehiclesOkOrReservationsByConstructorTablePage,
    limit: 5,
    filters: {
      groupBy: 'constructor',
    },
  })

  const [
    vehiclesOkOrReservationsByConstructorTableData,
    setVehiclesOkOrReservationsByConstructorTableData,
  ] = useState(null)

  // Add state for selected view persistence for recontrol
  const [
    vehiclesOkOrReservationsSelectedView,
    setVehiclesOkOrReservationsSelectedView,
  ] = useQueryState({
    paramName: 'vehiclesOkOrReservationsSelectedView',
    defaultValue: 0,
    history,
    query,
  })

  useEffect(() => {
    dataProvider
      .get('/dashboard', {})
      .then((response) => setCounts(response.data))
      .catch(() => setFailedLoad(true))
  }, [dataProvider])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  useEffect(() => {
    dataProvider
      .getList('/dashboard/data/vehicles-waiting-first-control', {
        range: {
          page: vehiclesWaitingFirstControlByVehicleTypeTableState.page + 1,
          limit: vehiclesWaitingFirstControlByVehicleTypeTableState.limit,
        },
        filters: vehiclesWaitingFirstControlByVehicleTypeTableState.filters,
      })
      .then((response) => {
        setVehiclesWaitingFirstControlByVehicleTypeTableData(response.data)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setVehiclesWaitingFirstControlByVehicleTypeTablePage(
      vehiclesWaitingFirstControlByVehicleTypeTableState.page
    )
  }, [vehiclesWaitingFirstControlByVehicleTypeTableState])

  useEffect(() => {
    dataProvider
      .getList('/dashboard/data/vehicles-waiting-first-control', {
        range: {
          page: vehiclesWaitingFirstControlByConstructorTableState.page + 1,
          limit: vehiclesWaitingFirstControlByConstructorTableState.limit,
        },
        filters: vehiclesWaitingFirstControlByConstructorTableState.filters,
      })
      .then((response) => {
        setVehiclesWaitingFirstControlByConstructorTableData(response.data)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setVehiclesWaitingFirstControlByConstructorTablePage(
      vehiclesWaitingFirstControlByConstructorTableState.page
    )
  }, [vehiclesWaitingFirstControlByConstructorTableState])

  // Recontrol Vehicle Type Table data fetch
  useEffect(() => {
    dataProvider
      .getList('/dashboard/data/vehicles-waiting-recontrol', {
        range: {
          page: vehiclesWaitingRecontrolByVehicleTypeTableState.page + 1,
          limit: vehiclesWaitingRecontrolByVehicleTypeTableState.limit,
        },
        filters: vehiclesWaitingRecontrolByVehicleTypeTableState.filters,
      })
      .then((response) => {
        setVehiclesWaitingRecontrolByVehicleTypeTableData(response.data)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setVehiclesWaitingRecontrolByVehicleTypeTablePage(
      vehiclesWaitingRecontrolByVehicleTypeTableState.page
    )
  }, [vehiclesWaitingRecontrolByVehicleTypeTableState])

  // Recontrol Constructor Table data fetch
  useEffect(() => {
    dataProvider
      .getList('/dashboard/data/vehicles-waiting-recontrol', {
        range: {
          page: vehiclesWaitingRecontrolByConstructorTableState.page + 1,
          limit: vehiclesWaitingRecontrolByConstructorTableState.limit,
        },
        filters: vehiclesWaitingRecontrolByConstructorTableState.filters,
      })
      .then((response) => {
        setVehiclesWaitingRecontrolByConstructorTableData(response.data)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setVehiclesWaitingRecontrolByConstructorTablePage(
      vehiclesWaitingRecontrolByConstructorTableState.page
    )
  }, [vehiclesWaitingRecontrolByConstructorTableState])

  // Fetch data for Vehicles OK or Reservations by Vehicle Type
  useEffect(() => {
    dataProvider
      .getList('/dashboard/data/vehicles-ok-or-reservations', {
        range: {
          page: vehiclesOkOrReservationsByVehicleTypeTableState.page + 1,
          limit: vehiclesOkOrReservationsByVehicleTypeTableState.limit,
        },
        filters: vehiclesOkOrReservationsByVehicleTypeTableState.filters,
      })
      .then((response) => {
        setVehiclesOkOrReservationsByVehicleTypeTableData(response.data)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setVehiclesOkOrReservationsByVehicleTypeTablePage(
      vehiclesOkOrReservationsByVehicleTypeTableState.page
    )
  }, [vehiclesOkOrReservationsByVehicleTypeTableState])

  // Fetch data for Vehicles OK or Reservations by Constructor
  useEffect(() => {
    dataProvider
      .getList('/dashboard/data/vehicles-ok-or-reservations', {
        range: {
          page: vehiclesOkOrReservationsByConstructorTableState.page + 1,
          limit: vehiclesOkOrReservationsByConstructorTableState.limit,
        },
        filters: vehiclesOkOrReservationsByConstructorTableState.filters,
      })
      .then((response) => {
        setVehiclesOkOrReservationsByConstructorTableData(response.data)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setVehiclesOkOrReservationsByConstructorTablePage(
      vehiclesOkOrReservationsByConstructorTableState.page
    )
  }, [vehiclesOkOrReservationsByConstructorTableState])

  const onAddActionsMenuClose = () => {
    setAddActionsAnchorEl({
      ...addActionsAnchorEl,
      anchorEl: null,
      items: [],
    })
  }

  const onAddActions = (currentTarget) => {
    const menuItems = []

    menuItems.push({
      title: t('vehicle-types-list.manual-add'),
      onClick: () => {
        onAddActionsMenuClose()
        setOpenCreateVehicleTypeDialog(true)
      },
    })
    menuItems.push({
      title: t('vehicle-types-list.bulk-add'),
      onClick: () => {
        onAddActionsMenuClose()
        setOpenCreateVehicleTypeBulkDialog(true)
      },
    })

    setAddActionsAnchorEl({
      ...addActionsAnchorEl,
      anchorEl: currentTarget,
      items: menuItems,
    })
  }

  const vehiclesWaitingFirstControlByVehicleTypeTableColumns = generateColumns({
    t,
  })

  const vehiclesWaitingFirstControlByConstructorTableColumns = generateColumns({
    t,
  })

  const vehiclesWaitingRecontrolByVehicleTypeTableColumns = generateColumns({
    t,
    needsRecontrol: true,
  })

  const vehiclesWaitingRecontrolByConstructorTableColumns = generateColumns({
    t,
    needsRecontrol: true,
  })

  // Define columns for Vehicles OK or Reservations
  const vehiclesOkOrReservationsByVehicleTypeTableColumns = generateColumns({
    t,
    needsRecontrol: false,
    isValidated: true,
  })

  const vehiclesOkOrReservationsByConstructorTableColumns = generateColumns({
    t,
    needsRecontrol: false,
    isValidated: true,
  })

  // still loading
  if (!counts) {
    return <Loading />
  }
  // local
  return (
    <>
      <CreateVehicleTypeBulkDialog
        open={openCreateVehicleTypeBulkDialog}
        onClose={() => {
          setOpenCreateVehicleTypeBulkDialog(false)
        }}
        onCreated={() => {
          setOpenCreateVehicleTypeBulkDialog(false)
        }}
      />
      <CreateChassisDialog
        open={openCreateChassisDialog}
        onClose={() => setOpenCreateChassisDialog(false)}
        onCreated={() => setOpenCreateChassisDialog(false)}
      />
      <CreateVehicleTypeDialog
        open={openCreateVehicleTypeDialog}
        onClose={() => setOpenCreateVehicleTypeDialog(false)}
        onCreated={() => setOpenCreateVehicleTypeDialog(false)}
      />
      <Menu
        keepMounted
        anchorEl={addActionsAnchorEl.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onAddActionsMenuClose}
        open={Boolean(addActionsAnchorEl.anchorEl)}
      >
        {addActionsAnchorEl.items.map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('dashboard-view.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          {hasPermission(Permissions.PAGE_DASHBOARD_ADD_CHASSIS, user) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth={fullWidth}
              onClick={() => setOpenCreateChassisDialog(true)}
            >
              {t('dashboard-view.add-chassis')}
            </Button>
          )}
          {hasPermission(Permissions.PAGE_DASHBOARD_ADD_VEHICLE_TYPE, user) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth={fullWidth}
              onClick={(event) => onAddActions(event.currentTarget)}
            >
              {t('dashboard-view.add-vehicle-type')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        justifyContent="flex-start"
        className={classes.cardsContainer}
      >
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title={t('dashboard-view.vehicles-to-check')}
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
          >
            <div className={classes.visitsNumberContainer}>
              <Typography variant="h3">{counts.controlCount}</Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title={t('dashboard-view.vehicles-to-recheck')}
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
          >
            <div className={classes.visitsNumberContainer}>
              <Typography variant="h3">{counts.controlAgainCount}</Typography>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        justifyContent="flex-start"
        className={classes.cardsContainer}
      >
        <Grid item lg={6} md={8} sm={10} xs={12}>
          <BFTableInfoCard
            title={t(
              'dashboard-view.data.vehicles-waiting-first-control.title'
            )}
            data={[
              vehiclesWaitingFirstControlByVehicleTypeTableData,
              vehiclesWaitingFirstControlByConstructorTableData,
            ]}
            state={[
              vehiclesWaitingFirstControlByVehicleTypeTableState,
              vehiclesWaitingFirstControlByConstructorTableState,
            ]}
            columns={[
              vehiclesWaitingFirstControlByVehicleTypeTableColumns,
              vehiclesWaitingFirstControlByConstructorTableColumns,
            ]}
            buttons={[
              t(
                'dashboard-view.data.vehicles-waiting-first-control.vehicle-type-button'
              ),
              t(
                'dashboard-view.data.vehicles-waiting-first-control.constructor-button'
              ),
            ]}
            backgroundColor="#FFFFFF"
            mainColor={theme.palette.primary.main}
            minHeight={450}
            isLoading={
              !vehiclesWaitingFirstControlByVehicleTypeTableData ||
              !vehiclesWaitingFirstControlByConstructorTableData
            }
            initialView={vehiclesWaitingFirstControlSelectedView}
            onViewChange={(view) =>
              setVehiclesWaitingFirstControlSelectedView(view)
            }
            onChangePage={(currentView, currentPage) => {
              if (currentView === 0) {
                setVehiclesWaitingFirstControlByVehicleTypeTableState(
                  (prevState) => ({
                    ...prevState,
                    page: currentPage,
                  })
                )
              } else {
                setVehiclesWaitingFirstControlByConstructorTableState(
                  (prevState) => ({
                    ...prevState,
                    page: currentPage,
                  })
                )
              }
            }}
            onSelectRow={(currentView, row) => {
              if (row && row.id) {
                history.push(`/chassis/detail/${row.id}`)
              }
            }}
          />
        </Grid>

        {/* New BFTableInfoCard for Recontrol */}
        <Grid item lg={6} md={8} sm={10} xs={12}>
          <BFTableInfoCard
            title={t('dashboard-view.data.vehicles-waiting-recontrol.title')}
            data={[
              vehiclesWaitingRecontrolByVehicleTypeTableData,
              vehiclesWaitingRecontrolByConstructorTableData,
            ]}
            state={[
              vehiclesWaitingRecontrolByVehicleTypeTableState,
              vehiclesWaitingRecontrolByConstructorTableState,
            ]}
            columns={[
              vehiclesWaitingRecontrolByVehicleTypeTableColumns,
              vehiclesWaitingRecontrolByConstructorTableColumns,
            ]}
            buttons={[
              t(
                'dashboard-view.data.vehicles-waiting-recontrol.vehicle-type-button'
              ),
              t(
                'dashboard-view.data.vehicles-waiting-recontrol.constructor-button'
              ),
            ]}
            backgroundColor="#FFFFFF"
            mainColor={theme.palette.primary.main}
            minHeight={450}
            isLoading={
              !vehiclesWaitingRecontrolByVehicleTypeTableData ||
              !vehiclesWaitingRecontrolByConstructorTableData
            }
            initialView={vehiclesWaitingRecontrolSelectedView}
            onViewChange={(view) =>
              setVehiclesWaitingRecontrolSelectedView(view)
            }
            onChangePage={(currentView, currentPage) => {
              if (currentView === 0) {
                setVehiclesWaitingRecontrolByVehicleTypeTableState(
                  (prevState) => ({
                    ...prevState,
                    page: currentPage,
                  })
                )
              } else {
                setVehiclesWaitingRecontrolByConstructorTableState(
                  (prevState) => ({
                    ...prevState,
                    page: currentPage,
                  })
                )
              }
            }}
            onSelectRow={(currentView, row) => {
              if (row && row.id) {
                history.push(`/chassis/detail/${row.id}`)
              }
            }}
          />
        </Grid>

        {/* New BFTableInfoCard for Vehicles OK or Reservations */}
        <Grid item lg={6} md={8} sm={10} xs={12} marginBottom={6}>
          <BFTableInfoCard
            title={t('dashboard-view.data.vehicles-ok-or-reservations.title')}
            data={[
              vehiclesOkOrReservationsByVehicleTypeTableData,
              vehiclesOkOrReservationsByConstructorTableData,
            ]}
            state={[
              vehiclesOkOrReservationsByVehicleTypeTableState,
              vehiclesOkOrReservationsByConstructorTableState,
            ]}
            columns={[
              vehiclesOkOrReservationsByVehicleTypeTableColumns,
              vehiclesOkOrReservationsByConstructorTableColumns,
            ]}
            buttons={[
              t(
                'dashboard-view.data.vehicles-ok-or-reservations.vehicle-type-button'
              ),
              t(
                'dashboard-view.data.vehicles-ok-or-reservations.constructor-button'
              ),
            ]}
            backgroundColor="#FFFFFF"
            mainColor={theme.palette.primary.main}
            minHeight={450}
            isLoading={
              !vehiclesOkOrReservationsByVehicleTypeTableData ||
              !vehiclesOkOrReservationsByConstructorTableData
            }
            initialView={vehiclesOkOrReservationsSelectedView}
            onViewChange={(view) =>
              setVehiclesOkOrReservationsSelectedView(view)
            }
            onChangePage={(currentView, currentPage) => {
              if (currentView === 0) {
                setVehiclesOkOrReservationsByVehicleTypeTableState(
                  (prevState) => ({
                    ...prevState,
                    page: currentPage,
                  })
                )
              } else {
                setVehiclesOkOrReservationsByConstructorTableState(
                  (prevState) => ({
                    ...prevState,
                    page: currentPage,
                  })
                )
              }
            }}
            onSelectRow={(currentView, row) => {
              if (row && row.id) {
                history.push(`/chassis/detail/${row.id}`)
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
