import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Skeleton, TableCell, TableRow, TableHead, TablePagination } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../assets/ic_check.svg';
import { motion, AnimatePresence } from 'framer-motion';

import BFTable from '../table';

const CardStyle = styled('div')(({ theme, backgroundColor }) => ({
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor,
  position: 'relative',
  overflow: 'hidden',
}));

const LoadingCardStyle = styled('div')(({ theme, backgroundColor }) => ({
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor,
}));

// Reduce text font size for table rows
const TableRowStyle = styled(TableRow)(({ theme, condition, isSelectable }) => ({
  backgroundColor: condition === 'true' && theme.palette.background.neutral,
  cursor: isSelectable ? 'pointer' : 'default',
  '&:hover': isSelectable
    ? {
      backgroundColor: theme.palette.action.hover,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
    }
    : {},
}));

BFTableInfoCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  state: PropTypes.array,
  columns: PropTypes.array,
  buttons: PropTypes.array,
  backgroundColor: PropTypes.string,
  mainColor: PropTypes.string,
  minHeight: PropTypes.number,
  isLoading: PropTypes.bool,
  onChangePage: PropTypes.func,
  onSelectRow: PropTypes.func,
  initialView: PropTypes.number,
  onViewChange: PropTypes.func,
};

export default function BFTableInfoCard({
  title,
  data,
  state,
  columns,
  buttons,
  backgroundColor,
  mainColor,
  minHeight,
  isLoading,
  onChangePage,
  onSelectRow,
  initialView = 0,
  onViewChange,
}) {
  const [currentView, setCurrentView] = useState(initialView);
  const theme = useTheme();

  // Update currentView when initialView changes
  useEffect(() => {
    setCurrentView(initialView);
  }, [initialView]);

  const direction = currentView === 0 ? 1 : -1; // Determines the slide direction

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? '-100%' : '100%',
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    }),
  };

  // Add custom row render with a text small size
  const customRowRender = (_, dataIndex, rowIndex) => {
    const row = data[currentView]?.items[dataIndex];
    const tableColumns = columns[currentView];

    if (row) {
      if (row?.isHeader) {
        // Render group header
        return (
          <TableRowStyle key={rowIndex} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
            <TableCell key={rowIndex} colSpan={tableColumns.length} style={{ textAlign: 'center', fontSize: 12 }}>
              {row.title}
            </TableCell>
          </TableRowStyle>
        );
      }

      // Render normal row
      return (
        <TableRowStyle key={rowIndex} onClick={() => onSelectRow(currentView, row)} isSelectable>
          {tableColumns.map((column, colIndex) => {
            const cellValue = row[column.name];

            // Check if cell value is boolean and render icon if true
            if (typeof cellValue === 'boolean') {
              return (
                <TableCell key={colIndex} style={{ fontSize: 12, textAlign: 'center' }}>
                  {cellValue === true ? <CheckIcon /> : null}
                </TableCell>
              );
            }

            // Default rendering for non-boolean values
            return (
              <TableCell key={colIndex} style={{ fontSize: 12 }}>
                {cellValue}
              </TableCell>
            );
          })}
        </TableRowStyle>
      );
    }

    return null;
  };

  const CustomStyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1), // Reduce padding
    fontSize: 12, // Smaller text size
  }));

  const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    '.MuiTablePagination-toolbar': {
      minHeight: 'auto',
    },
    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
      fontSize: 12, // Smaller text size for pagination labels
    },
    '.MuiTablePagination-select': {
      fontSize: 12, // Smaller text size for the select dropdown
    },
  }));

  const customFooter = (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
    <TableRow>
      <CustomStyledTableCell colSpan={columns[currentView].length + 1}>
        <StyledTablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => changePage(newPage)}
          onRowsPerPageChange={changeRowsPerPage}
        />
      </CustomStyledTableCell>
    </TableRow>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
  }));

  const customHeader = (props) => (
    <TableHead {...props}>
      <tr>
        {columns[currentView].map((column, index) => (
          <StyledTableCell key={index}>{column.label}</StyledTableCell>
        ))}
      </tr>
    </TableHead>
  );

  const handleViewChange = (index) => {
    setCurrentView(index);
    if (onViewChange) {
      onViewChange(index);
    }
  };

  if (isLoading) {
    // Render Skeleton while loading
    return (
      <LoadingCardStyle backgroundColor={backgroundColor}>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={minHeight}
          animation="pulse"
          sx={{
            borderRadius: 2,
            backgroundColor: theme.palette.grey[200],
          }}
        />
      </LoadingCardStyle>
    );
  }

  return (
    <CardStyle backgroundColor={backgroundColor}>
      <Box marginBottom={2}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      {/* Selector Buttons */}
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        {buttons &&
          buttons.map((button, index) => (
            <Button
              key={index}
              style={{
                borderRadius: 20,
                color: currentView === index ? 'white' : mainColor,
                backgroundColor: currentView === index ? mainColor : 'white',
                borderColor: mainColor,
                fontSize: 12,
              }}
              variant={currentView === index ? 'contained' : 'outlined'}
              onClick={() => handleViewChange(index)}
            >
              {button}
            </Button>
          ))}
      </Box>
      {data && (
        <Box
          marginTop={2}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%', // Fully responsive
            minHeight: minHeight || 'auto', // Use 'auto' to adjust dynamically based on content
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentView}
              variants={variants}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.5 },
              }}
              style={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                alignItems: data[currentView]?.items?.length > 0 ? 'flex-start' : 'center',
                justifyContent: 'center',
                overflow: 'hidden', // Prevent overflow of the motion container
              }}
            >
              {/* Add shadow or border around the table */}
              <Box
                sx={{
                  width: '100%',
                  maxHeight: minHeight || '500px', // Limit the height to the minHeight
                  overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
                  overflowX: 'auto', // Enable horizontal scrolling
                  paddingBottom: theme.spacing(2), // Add padding to avoid excessive white space
                  border: `1px solid ${theme.palette.divider}`, // Add border
                  borderRadius: Number(theme.shape.borderRadius) * 0.5, // Add rounded corners
                }}
              >
                <BFTable
                  columns={columns[currentView]}
                  data={data[currentView]}
                  page={state[currentView]?.page}
                  onChangePage={(currentPage) => onChangePage(currentView, currentPage)}
                  customRowRender={customRowRender}
                  customHeaderRender={customHeader}
                  customFooterRender={customFooter}
                />
              </Box>
            </motion.div>
          </AnimatePresence>
        </Box>
      )}
      {/* Pagination Dots */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        {buttons.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: currentView === index ? mainColor : 'grey.500',
              marginX: 0.5,
            }}
          />
        ))}
      </Box>
    </CardStyle>
  );
}
