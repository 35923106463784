import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'

import './i18n'
import Themes from './themes'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { LayoutProvider } from './context/LayoutContext'
import { UserProvider } from './context/user/UserContext'
import { ApiDataProvider } from './context/DataApiContext'
import Loading from './components/Loading'
import { NotificationsProvider } from './context/NotificationsContext'
import { ConfirmationDialogProvider } from './context/ConfirmationDialogContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <LayoutProvider>
    <Suspense fallback={<Loading />}>
      <NotificationsProvider>
        <UserProvider>
          <ApiDataProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <ConfirmationDialogProvider>
                  <App />
                </ConfirmationDialogProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ApiDataProvider>
        </UserProvider>
      </NotificationsProvider>
    </Suspense>
  </LayoutProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
