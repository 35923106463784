import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import { Grid, Button, useMediaQuery, useTheme } from '@mui/material'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useDataApi } from '../../context/DataApiContext'

import useStyles from './styles'

// eslint-disable-next-line max-len
import CreateControlLocationDialog from '../../components/ControlLocation/CreateControlLocationDialog'
// eslint-disable-next-line max-len
import ModifyControlLocationDialog from '../../components/ControlLocation/ModifyControlLocationDialog'

import Table from '../../components/Table'
import generateColumns from './columns'

export default function ControlLocationsList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { showConfirmationDialog } = useConfirmationDialogProvider()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)
  const [openCreateControlLocationDialog, setOpenCreateControlLocationDialog] =
    useState(false)
  const [openModifyControlLocationDialog, setOpenModifyControlLocationDialog] =
    useState(false)
  const [controlLocationToModify, setControlLocationToModify] = useState(null)

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ page: tableState.page, sort: null, filters: null })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'control-location'

  useEffect(() => {
    dataProvider
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        false
      )
      .then((response) => {
        setItems(response.data.items)
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onModifyControlLocation = (rowIndex) => {
    setControlLocationToModify(tableData.items[rowIndex])
    setOpenModifyControlLocationDialog(true)
  }

  const onDeleteControlLocation = (rowIndex) => {
    const { id, name } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('control-locations-list.confirm-control-location-deletion', {
        name,
      }),
      () => {
        dataProvider
          .delete(`control-location`, { id })
          .then(() => {
            refreshData()
            showNotification(
              'success',
              t('control-locations-list.delete-success', {
                name,
              })
            )
          })
          .catch((err) => {
            if (err.response.data.error === 'CONTROL_LOCATION_NOT_FOUND') {
              showNotification(
                'error',
                t('control-locations-list.email-delete-not-found-error')
              )
            } else {
              showNotification('error', t('errors.api-error'))
            }
          })
      },
      t('common.delete')
    )
  }

  const tableColumns = generateColumns({
    t,
    classes,
    items,
    onModifyControlLocation,
    onDeleteControlLocation,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateControlLocationDialog
        open={openCreateControlLocationDialog}
        onClose={() => setOpenCreateControlLocationDialog(false)}
        onCreated={() => {
          setOpenCreateControlLocationDialog(false)
          refreshData()
        }}
      />
      <ModifyControlLocationDialog
        open={openModifyControlLocationDialog}
        onClose={() => setOpenModifyControlLocationDialog(false)}
        onModified={() => {
          setOpenModifyControlLocationDialog(false)
          refreshData()
        }}
        controlLocationToEdit={controlLocationToModify}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('control-locations-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateControlLocationDialog(true)}
          >
            {t('control-locations-list.add-control-location')}
          </Button>
        </Grid>
      </Grid>
      <Table
        id={resource}
        columns={tableColumns}
        data={tableData}
        page={tableState.page}
        sort={tableState.sort}
        onChangePage={(currentPage) => {
          setTableState({ ...tableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setTableState({ ...tableState, sort: newSort })
        }}
      />
    </>
  )
}
