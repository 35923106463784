import React from 'react'
import TableCell from '@mui/material/TableCell'

export default function generate({ t, needsRecontrol, isValidated }) {
  return [
    {
      name: 'nParc',
      label: t('common.nParc'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="nParc-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.nParc')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'nChassis',
      label: t('common.chassis'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="nChassis-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.chassis')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'operateur',
      label: t('common.operateur'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="operateur-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.operateur')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'depot',
      label: t('common.depot'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="depot-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.depot')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'location',
      label: t('common.location'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="location-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.location')}
            </TableCell>
          )
        },
      },
    },
    needsRecontrol && {
      name: 'lastControlNumber',
      label: t('common.control-number'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="lastControlNumber-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.control-number')}
            </TableCell>
          )
        },
        customBodyRender: (value, tableMeta) => {
          console.log(tableMeta)
          const controls =
            tableMeta.rowData?.[tableMeta.columnIndex - 1]?.controls || []
          return controls[0]?.controlNumber || ''
        },
      },
    },
    isValidated && {
      name: 'wasValidationOverriden',
      label: t('conformity.valid-overriden'),
      options: {
        sort: false,
        customHeadRender: () => {
          return (
            <TableCell
              key="wasValidationOverriden-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('conformity.valid-overriden')}
            </TableCell>
          )
        },
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
