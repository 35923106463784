import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'

export default function CreateControlLocationDialog({
  open,
  onClose,
  onCreated,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')

  const resource = 'control-location'

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setName('')
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    const body = {
      data: {
        name: name,
      },
    }

    setLoading(true)
    dataProvider
      .post(resource, body)
      .then(() => {
        dataProvider.cache.reset()
        showNotification('success', t('create-control-location-dialog.success'))
        onCreated()
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'CONTROL_LOCATION_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('create-control-location-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }

        setLoading(false)
      })
  }
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-control-location-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('create-control-location-dialog.control-location-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
