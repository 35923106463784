import React from 'react'

// components
import { Typography, Link } from '@mui/material'
import enums from '../../utils/enums'

export default function generate({ t, onDocumentsClicked }) {
  return [
    {
      name: 'control.chassis.nChassis',
      label: t('common.chassis'),
      options: {
        sort: false,
      },
    },
    {
      name: 'documentTypeName',
      label: t('documents.document-type'),
      options: {
        sort: false,
      },
    },
    {
      name: 'document.originalName',
      label: t('common.title'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (val) {
            return (
              <Typography>
                <Link
                  id="subtitle"
                  align="left"
                  variant="body2"
                  onClick={() => onDocumentsClicked(tableMeta.rowIndex)}
                >
                  {val}
                </Link>
              </Typography>
            )
          }
          return t('documents.missing')
        },
      },
    },
    {
      name: 'description',
      label: 'Non-conformité',
      options: {
        sort: false,
      },
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          switch (val) {
            case enums.CONFORMITY.INVALID:
              return t('conformity.invalid')
            case enums.CONFORMITY.VALID:
              return t('conformity.valid')
            case enums.CONFORMITY.AWAITING_CONTROL:
              return t('conformity.waiting-result')
            default:
              return t('errors.not-valid')
          }
        },
      },
    },
    {
      name: 'control.controlLocation.name',
      label: t('common.control-location'),
    },
    {
      name: 'control.chassis.nParc',
      label: t('common.nParc'),
    },
  ]
}
